//
// Nunito fonts
//
$assetsPath: '../../..';

@font-face {
  font-family: 'Nunito';
  src: url('#{$assetsPath}/fonts/Nunito-Light.eot');
  src: local('Nunito Light'), local('Nunito-Light'), url('#{$assetsPath}/fonts/Nunito-Light.eot?#iefix') format('embedded-opentype'), url('#{$assetsPath}/fonts/Nunito-Light.woff') format('woff'), url('#{$assetsPath}/fonts/Nunito-Light.ttf') format('truetype'), url('#{$assetsPath}/fonts/Nunito-Light.svg#Roboto') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('#{$assetsPath}/fonts/Nunito-Regular.eot');
  src: local('Nunito Regular'), local('Nunito-Regular'), url('#{$assetsPath}/fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'), url('#{$assetsPath}/fonts/Nunito-Regular.woff') format('woff'), url('#{$assetsPath}/fonts/Nunito-Regular.ttf') format('truetype'), url('#{$assetsPath}/fonts/Nunito-Regular.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('#{$assetsPath}/fonts/Nunito-Medium.eot');
  src: local('Nunito Medium'), local('Nunito-Medium'), url('#{$assetsPath}/fonts/Nunito-Medium.eot?#iefix') format('embedded-opentype'), url('#{$assetsPath}/fonts/Nunito-Medium.woff') format('woff'), url('#{$assetsPath}/fonts/Nunito-Medium.ttf') format('truetype'), url('#{$assetsPath}/fonts/Nunito-Medium.svg#Roboto') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('#{$assetsPath}/fonts/Nunito-SemiBold.eot');
  src: local('Nunito SemiBold'), local('Nunito-SemiBold'), url('#{$assetsPath}/fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'), url('#{$assetsPath}/fonts/Nunito-SemiBold.woff') format('woff'), url('#{$assetsPath}/fonts/Nunito-SemiBold.ttf') format('truetype'), url('#{$assetsPath}/fonts/Nunito-SemiBold.svg#Roboto') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('#{$assetsPath}/fonts/Nunito-Bold.eot');
  src: local('Nunito Bold'), local('Nunito-Bold'), url('#{$assetsPath}/fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'), url('#{$assetsPath}/fonts/Nunito-Bold.woff') format('woff'), url('#{$assetsPath}/fonts/Nunito-Bold.ttf') format('truetype'), url('#{$assetsPath}/fonts/Nunito-Bold.svg#Roboto') format('svg');
  font-weight: 700;
  font-style: normal;
}