.internal-layout {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.internal-layout-container {
  flex: 1;
  flex-grow: 1;
}

.sticky-button {
  position: sticky;
  bottom: 0px; /* Adjust this value as needed */
  z-index: 1000; /* Ensures it's above other elements */
  background-color: white; /* Optional: maintains visibility */
  padding: 10px; /* Optional: spacing around the button */
}