.map-row {
  align-items: center;
  justify-content: center;

  height: 73vh;
}

.google-maps {
  height: 73vh;
}

.buildings {
  height: 73vh;
  overflow-y: auto;
  overflow-x: visible;
}
