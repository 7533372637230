/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.top-border {
  border-top: 2px solid #f0f0f0;
}

.bottom-border {
  border-bottom: 2px solid #f0f0f0;
}

.tab-layout-content-height {
  height: 91vh;
}

.border-purple {
  border-color: #6b5eae !important;
}

.border-light-gray {
  border-color: #b3b3b3 !important;
}

.bg-purple {
  background-color: #6b5eae !important;
}
