.tab-layout {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}

/* 
.tab-layout-container {
  flex: 1;
  flex-grow: 1;
} */
